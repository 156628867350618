import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import { useThemeContext } from './context/useThemeContext';
import { useSelector } from 'react-redux';
import { removeToken, removeUser, removeCompanyId, removeTaxNumber, removeProgram, removeIsAuthenticatedWithVkn } from './service/tokenServices';
import { Avatar } from 'primereact/avatar';
import { updateProfile } from './service/request/userRequest';
import { Toast } from 'primereact/toast';
import UpdatePasswordDiaolog from './pages/UpdatePasswordDialog';

const AppInlineMenu = (props) => {
    const { selectedCompany } = useSelector((state) => state.userCompany);
    const { colorScheme, onColorSchemeChange } = useThemeContext();
    const menuRef = useRef(null);
    const toast = useRef(null);
    const { user, isAuthenticatedWithVkn } = useSelector((state) => state.auth);
    const [passwordChangeDialog, setPasswordChangeDialog] = useState(false);

    const isSlim = () => {
        return props.menuMode === 'slim';
    };

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };

    const handleChangeTheme = () => {
        if (colorScheme === 'light') {
            onColorSchemeChange('dark');
        } else {
            onColorSchemeChange('light');
        }
    };

    const handleLogout = (e) => {
        e.preventDefault();
        removeToken();
        removeUser();
        removeCompanyId();
        removeTaxNumber();
        removeProgram();
        removeIsAuthenticatedWithVkn();
        window.location.reload();
    };

    const handleUpdatePassword = async (newPassword) => {
        setPasswordChangeDialog(false);
        try {
            await updateProfile({ password: newPassword });
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şifre Güncellendi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
    };

    return (
        <>
            <Toast ref={toast} />
            {!isMobile() && (isStatic() || isSlim() || isSidebar()) && (
                <div className={classNames('layout-inline-menu', { 'layout-inline-menu-active': props.activeInlineProfile })}>
                    <div className="px-4">
                        <span className="p-badge h-full w-full py-2">{selectedCompany?.name}</span>
                    </div>
                    <button className="layout-inline-menu-action p-link flex justify-content-center align-items-center" onClick={props.onChangeActiveInlineMenu}>
                        <Avatar icon="pi pi-user" className="mr-2" size="large" style={{ backgroundColor: '#ffff00', color: '#251da2' }} shape="circle" />
                        <div>
                            <span className="layout-inline-menu-text mb-2">{user?.name}</span>
                        </div>
                        <i className="layout-inline-menu-icon pi pi-angle-down"></i>
                    </button>
                    <CSSTransition nodeRef={menuRef} classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.activeInlineProfile} unmountOnExit>
                        <ul ref={menuRef} className="layout-inline-menu-action-panel">
                            {!isAuthenticatedWithVkn && (
                                <li className="layout-inline-menu-action-item">
                                    <button className="p-link" onClick={() => setPasswordChangeDialog(true)}>
                                        <i className="pi pi-key pi-fw"></i>
                                        <span>Şifre Değiştir</span>
                                    </button>
                                </li>
                            )}
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={() => handleChangeTheme()}>
                                    {colorScheme === 'light' ? <i className="topbar-icon pi pi-fw pi-moon"></i> : <i className="topbar-icon pi pi-fw pi-sun"></i>}
                                    <span>{colorScheme === 'light' ? 'Karanlık Tema' : 'Aydınlık Tema'}</span>
                                </button>
                            </li>
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={(e) => handleLogout(e)}>
                                    <i className="pi pi-power-off pi-fw"></i>
                                    <span>Çıkış Yap</span>
                                </button>
                            </li>
                        </ul>
                    </CSSTransition>

                    <UpdatePasswordDiaolog visible={passwordChangeDialog} onHide={() => setPasswordChangeDialog(false)} onSubmit={handleUpdatePassword} />
                </div>
            )}
        </>
    );
};

export default AppInlineMenu;
