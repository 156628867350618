import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getToken, getUser, getCompanyId, getTaxNumber, getProgram, getIsAuthenticatedWithVkn } from './service/tokenServices';
import { setIsAuthenticated, setStoreUser, setTaxNumber, setProgram, setIsAuthenticatedWithVckn } from './store/auth/authSlice';

import { setSelectCompany } from './store/company/userCompanySlice';

import App from './App';
import { Login } from './pages/Login';
import Loading from './pages/Loading';
import { ForgotPassword } from './pages/ForgotPassword';
import { Chat } from './pages/Chat';
import LoginWithVkn from './pages/LoginWithVkn';

const AppWrapper = () => {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const { selectedCompany } = useSelector((state) => state.userCompany);

    const dispatch = useDispatch();

    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const [isLoading, setIsLoading] = useState(true);

    const setOptions = async () => {
        const token = getToken();
        const user = getUser();
        const companyId = getCompanyId();
        const taxNumber = getTaxNumber();
        const program = getProgram();
        const isAuthenticatedWithVkn = getIsAuthenticatedWithVkn();
        if (token || user || companyId) {
            dispatch(setIsAuthenticated(true));
            dispatch(setStoreUser(user));
            dispatch(setSelectCompany(companyId));
            dispatch(setTaxNumber(taxNumber));
            dispatch(setProgram(program));
            dispatch(setIsAuthenticatedWithVckn(isAuthenticatedWithVkn));
        } else {
            dispatch(setIsAuthenticated(false));
            dispatch(setStoreUser(null));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setOptions();
    }, []);

    if (isLoading) {
        return (
            <div style={{ height: '100vh' }}>
                <Loading />
            </div>
        );
    }

    return (
        <Routes>
            {isAuthenticated && (selectedCompany || getCompanyId()) ? (
                <>
                    <Route path="/login" element={<Navigate to="/dashboard" />} />
                    <Route path="/*" element={<App />} />
                </>
            ) : (
                <>
                    <Route path="/login" element={<Login />} />
                    <Route path="/*" element={<Navigate to="/login" />} />
                    <Route path="/forgotPassword" element={<ForgotPassword />} />
                </>
            )}
            <Route path="/chat" element={<Chat />} />
            <Route path="/loginWithVkn/:vkn" element={<LoginWithVkn />} />
        </Routes>
    );
};

export default AppWrapper;
