import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { removeToken, removeUser, setToken, setUser, setTaxNumberLocal, setProgramLocal, setIsAuthenticatedWithVknLocal } from '../../service/tokenServices';
import { login, logout } from '../../service/request/authRequest';

export const loginAsyncThunk = createAsyncThunk('auth/login', async ({ email, password }) => {
    const response = await login(email, password);
    return response.data;
});

export const logoutAsyncThunk = createAsyncThunk('auth/logout', async () => {
    const response = await logout();
    return response.data;
});

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        isAuthenticated: false,
        isAuthenticatedWithVkn: false,
        taxNumber: null,
        program: null,
        isAuthenticatedWithVknLocal: false
    },
    reducers: {
        setStoreUser: (state, action) => {
            state.user = action.payload;
        },
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        setIsAuthenticatedWithVkn: (state, action) => {
            state.isAuthenticated = action.payload.isAuthenticated;
            setUser(action.payload.user);
            setToken(action.payload.token);
            setTaxNumberLocal(action.payload.taxNumber);
            setProgramLocal(action.payload.program);
            setIsAuthenticatedWithVknLocal(true);
            state.isAuthenticatedWithVkn = true;
            state.user = action.payload.user;
            state.taxNumber = action.payload.taxNumber;
            state.program = action.payload.program;
        },
        setTaxNumber: (state, action) => {
            state.taxNumber = action.payload;
        },
        setProgram: (state, action) => {
            state.program = action.payload;
        },
        setIsAuthenticatedWithVckn: (state, action) => {
            state.isAuthenticatedWithVkn = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(loginAsyncThunk.fulfilled, (state, action) => {
                state.user = action.payload.data.user;
                setToken(action.payload.data.token);
                setUser(action.payload.data.user);
                state.isAuthenticated = true;
            })
            .addCase(logoutAsyncThunk.fulfilled, (state, action) => {
                state.user = null;
                state.isAuthenticated = false;
                removeToken();
                removeUser();
            });
    }
});

export const { setStoreUser, setIsAuthenticated, setIsAuthenticatedWithVkn, setTaxNumber, setProgram, setIsAuthenticatedWithVckn } = authSlice.actions;
export default authSlice.reducer;
