import { companies } from '../url';
import axios from 'axios';

export const getAllCompanies = async () => {
    return await axios.get(companies.getAllCompanies);
};

export const createCompany = async (data) => {
    return await axios.post(companies.createCompany, data);
};

export const updateCompany = async (data) => {
    return await axios.put(companies.updateCompany, data);
};

export const deleteCompany = async (id) => {
    return await axios.delete(companies.deleteCompany, { data: { companyId: id } });
};

export const attachUser = async (data) => {
    return await axios.post(companies.attachUser, data);
};

export const detachUser = async (data) => {
    return await axios.post(companies.detachUser, data);
};

export const attachModule = async (data) => {
    return await axios.post(companies.attachModule, data);
};

export const detachModule = async (data) => {
    return await axios.post(companies.detachModule, data);
};

export const getModulesByCompany = async (id) => {
    return await axios.get(companies.getModulesByCompany + `?id=${id}`);
};

export const setCompanySettings = async (data) => {
    return await axios.post(companies.setCompanySettings, data);
};

export const getCompanySettings = async (data) => {
    return await axios.get(companies.getCompanySettings + `?companyId=${data.companyId}&moduleId=${data.moduleId}`);
};

export const getByUser = async () => {
    return await axios.get(companies.getByUser);
};

export const getById = async (id) => {
    return await axios.get(companies.getById + `?id=${id}`);
};

export const updateSettings = async (data) => {
    return await axios.post(companies.updateSettings, data);
};

export const loginWithVkn = async (data) => {
    return await axios.post(companies.loginWithVkn, data);
};
