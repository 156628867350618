import axios from 'axios';
import CryptoJS from 'crypto-js';

const TOKEN = 'i-uyum-token';
const USER = 'i-uyum-user';

export const getToken = () => {
    const encryptedToken = localStorage.getItem(TOKEN);
    if (encryptedToken) {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, 'secret-key');
        const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedToken;
    }
    return null;
};

export const setToken = (token) => {
    const encryptedToken = CryptoJS.AES.encrypt(token, 'secret-key').toString();

    localStorage.setItem(TOKEN, encryptedToken);
};

export const setTaxNumberLocal = (taxNumber) => {
    localStorage.setItem('taxNumber', taxNumber);
};

export const setProgramLocal = (program) => {
    localStorage.setItem('program', program);
};

export const setIsAuthenticatedWithVknLocal = (isAuthenticatedWithVkn) => {
    localStorage.setItem('isAuthenticatedWithVkn', isAuthenticatedWithVkn);
};

export const removeToken = () => {
    localStorage.removeItem(TOKEN);
};

export const removeTaxNumber = () => {
    localStorage.removeItem('taxNumber');
};

export const removeProgram = () => {
    localStorage.removeItem('program');
};

export const removeIsAuthenticatedWithVkn = () => {
    localStorage.removeItem('isAuthenticatedWithVkn');
};

export const getIsAuthenticatedWithVkn = () => {
    return localStorage.getItem('isAuthenticatedWithVkn') === 'true';
};

export const getTaxNumber = () => {
    return localStorage.getItem('taxNumber');
};

export const getProgram = () => {
    return localStorage.getItem('program');
};

export const getUser = () => {
    const encryptedUser = localStorage.getItem(USER);
    if (encryptedUser) {
        const bytes = CryptoJS.AES.decrypt(encryptedUser, 'secret-key');
        const decryptedUser = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedUser);
    }
    return null;
};

export const setUser = (user) => {
    const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(user), 'secret-key').toString();
    localStorage.setItem(USER, encryptedUser);
};

export const removeUser = () => {
    localStorage.removeItem(USER);
};

export const setCompanyId = (id) => {
    const encryptedCompanyId = CryptoJS.AES.encrypt(JSON.stringify(id), 'secret-key').toString();
    localStorage.setItem('companyId', encryptedCompanyId);
};

export const getCompanyId = () => {
    const encryptedCompanyId = localStorage.getItem('companyId');
    if (encryptedCompanyId) {
        const bytes = CryptoJS.AES.decrypt(encryptedCompanyId, 'secret-key');
        const decryptedCompanyId = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedCompanyId);
    }
    return null;
};

export const removeCompanyId = () => {
    localStorage.removeItem('companyId');
};

export const setupAxiosInterceptors = () => {
    axios.interceptors.request.use(
        (config) => {
            const token = getToken();
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 401 && error?.response?.data?.message === 'Unauthorized') {
                removeToken();
                removeUser();
                window.location.reload();
            } else if (error?.response?.status >= 500) {
                window.location.href = '/#/error';
            }
            return Promise.reject(error);
        }
    );
};
