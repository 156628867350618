import axios from 'axios';

let BASE_URL = process.env.REACT_APP_INT_URL;

const lucaReq = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

async function loginLuca(username, password, customerNumber) {
    return await lucaReq.post(`${BASE_URL}/luca/login`, {
        username,
        password,
        customerNo: customerNumber
    });
}

async function getCompanyAndPeriodList(jSessionId, wMonId) {
    return await lucaReq.post(`${BASE_URL}/luca/companyAndPeriodList`, { jSessionId, wMonId });
}

async function getReceiptListAndDetails(jSessionId, wMonId, companyId, periodId, startDate, endDate, belgeTurleri) {
    return await lucaReq.post(`${BASE_URL}/luca/receiptListAndDetails`, { jSessionId: jSessionId, wMonId: wMonId, companyId, periodId, startDate, endDate, belgeTurleri });
}

async function sendReceiptsOnLuca(data) {
    return await lucaReq.post(`${BASE_URL}/luca/sendReceipt`, data);
}

async function sendCaptcha(data) {
    return await lucaReq.post(`${BASE_URL}/luca/captchaControl`, data);
}

class LucaRequest {
    static async login(username, password, customerNumber) {
        try {
            const response = await lucaReq.post(`${BASE_URL}/luca/login`, {
                username,
                password,
                customerNo: customerNumber
            });

            this.jSessionId = response.data.data.jSessionId;
            this.wMonId = response.data.data.wMonId;

            const companyAndPeriodList = await this.getCompanyAndPeriodList(this.jSessionId, this.wMonId);

            return companyAndPeriodList;
        } catch (error) {
            return error;
        }
    }

    static async getCompanyAndPeriodList(jSessionId, wMonId) {
        try {
            const response = await lucaReq.post(`${BASE_URL}/luca/companyAndPeriodList`, { jSessionId, wMonId });
            return response.data.data;
        } catch (error) {
            return error;
        }
    }
}

export { loginLuca, getCompanyAndPeriodList, LucaRequest, getReceiptListAndDetails, sendReceiptsOnLuca, sendCaptcha };
