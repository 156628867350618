import React, { useEffect, useState } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import Loading from '../Loading.js';
import { localeDate, formatDate, getCSVStatus, HeaderProgram, formatDateAndTime, selectLastThreeMonths } from './utils/utils.js';
import { deleteCsv, getCsvList, sendCsv } from '../module/datasoft/utils/DatasoftRequest.js';
import { getCompany } from './eDefterDatasoft/utils/utils.js';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

export default function RapidInvoicePage() {
    const { selectedCompany } = useSelector((state) => state.userCompany);
    const [invoices, setInvoices] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedReceipt, setSelectedReceipt] = useState([]);
    const [pending, setPending] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(selectLastThreeMonths());
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const { isAuthenticatedWithVkn, taxNumber } = useSelector((state) => state.auth);

    const dt = useRef(null);
    const toast = useRef(null);
    addLocale('tr', localeDate);

    useEffect(() => {
        if (selectedMonth) {
            setStartDate(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1));
            setEndDate(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0));
        }
    }, [selectedMonth]);

    useEffect(() => {
        if (isAuthenticatedWithVkn) {
            setSelectedCompanyCode(selectedCompany.modules.map((module) => module.setting.settings.program.lucaCompanies.filter((company) => company.taxId === taxNumber))[0][0].companyCode);
        }
    }, [selectedCompany, taxNumber, isAuthenticatedWithVkn]);

    const getReceiptList = async () => {
        setSelectedReceipt([]);
        setLoading(true);
        let selectedModule = selectedCompany.modules.find((module) => module.title === 'E-Defter');
        const company = selectedModule.setting.settings.program.lucaCompanies.filter((company) => company.companyCode === selectedCompanyCode)[0];

        let invoiceList = null;
        try {
            invoiceList = await getCsvList({
                taxId: company.taxId,
                startDate: formatDate(startDate, 'dashReverse'),
                endDate: formatDate(endDate, 'dashReverse')
            });
            const copy = [...invoiceList.data.data];
            setInvoices(copy);
            setLoading(false);
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: invoiceList.data.message, life: 3000 });
            }, 100);
        } catch (error) {
            setLoading(false);
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
            }, 100);
            return;
        }
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const sendReceipts = async (rowData) => {
        const module = selectedCompany.modules.filter((module) => module.title === 'E-Defter')[0];
        const company = module.setting.settings.program.lucaCompanies.filter((company) => company.companyCode === selectedCompanyCode)[0];
        const reqData = {
            taxId: company.taxId,
            id: rowData.id,
            webServiceUrl: company.webService.url,
            username: company.webService.requirements.username,
            password: company.webService.requirements.password
        };

        setPending(true);
        try {
            const res = await sendCsv(reqData);

            if (res.data.success === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.data.message, life: 3000 });
            } else {
                setPending(false);
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: res.data.message, life: 3000 });
                setTimeout(() => {
                    getReceiptList();
                }, 3500);
            }
        } catch (error) {
            setPending(false);
            error.code === 'ERR_NETWORK'
                ? toast.current.show({ severity: 'error', summary: 'Hata', detail: 'İstek zaman aşımına uğradı', life: 3000 })
                : toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className="flex align-items-center gap-5">
                <h4 className="m-0">Defter Listesi</h4>
            </div>
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const downloadCsv = (rowData) => {
        const link = document.createElement('a');
        link.href = rowData.csvFilePath;
        link.download = rowData.csvFileName;
        link.click();
    };

    const deleteCsvFile = async () => {
        try {
            await deleteCsv({ taxId: selectedReceipt[0].taxId, id: selectedReceipt[0].id.toString() });
            await getReceiptList();
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Dosya silindi', life: 3000 });
            }, 100);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
        setDeleteVisible(false);
        setSelectedReceipt([]);
    };

    const footerTemplate = (
        <div>
            <Button label="Hayır" icon="pi pi-times" onClick={() => setDeleteVisible(false)} className="p-button-danger text-white" />
            <Button label="Evet" icon="pi pi-check" onClick={() => deleteCsvFile()} className="p-button-success text-white" />
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="align-items-center justify-content-center flex">
                {/*<Button
                    severity="success"
                    tooltip="İndir"
                    size="small"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    className="text-white mr-2"
                    icon="pi pi-download"
                    onClick={() => downloadCsv(rowData)}
                    loading={pending}
                />*/}

                {rowData.status !== '2' && (
                    <Button
                        severity="info"
                        tooltip="Gönder"
                        size="small"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        className="text-white"
                        icon="pi pi-send"
                        onClick={() => sendReceipts(rowData)}
                        loading={pending}
                    />
                )}

                <Button
                    severity="danger"
                    tooltip="Sil"
                    size="small"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    className="text-white ml-2"
                    icon="pi pi-trash"
                    onClick={() => {
                        setSelectedReceipt([rowData]);
                        setDeleteVisible(true);
                    }}
                />
            </div>
        );
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.value);
        setStartDate(new Date(e.value.getFullYear(), e.value.getMonth(), 1));
        setEndDate(new Date(e.value.getFullYear(), e.value.getMonth() + 1, 0));
    };

    return (
        <div>
            <Toast ref={toast} />
            <HeaderProgram selectedCompany={selectedCompany} moduleName="E-Defter" />
            <br />
            <h3 className="mb-5">Defter Kaynak Listesi</h3>
            <div className="card flex align-items-end justify-content-between">
                <div className="flex align-items-end gap-5">
                    <div className="w-17rem">
                        <label className="mb-2 block">Şirketler</label>
                        <Dropdown
                            dataKey="_id"
                            value={selectedCompanyCode}
                            onChange={(e) => setSelectedCompanyCode(e.value)}
                            options={getCompany(selectedCompany)}
                            optionLabel="label"
                            placeholder="Şirket Seçiniz"
                            filter
                            className="w-full"
                            emptyMessage="Şirket Bulunamadı"
                            disabled={isAuthenticatedWithVkn}
                        />
                    </div>
                    <div className="w-17rem">
                        <label className="mb-2 block">E-Defter Dönemi</label>
                        <Calendar className="w-full" value={selectedMonth} view="month" dateFormat="mm/yy" locale="tr" onChange={handleMonthChange} showIcon placeholder="Lütfen bir ay seçiniz" />
                    </div>
                    <Button icon="pi pi-inbox" onClick={() => getReceiptList()} className="px-4" label="Defter Kaynağını Getir" disabled={!(startDate && endDate)} />
                </div>
            </div>

            <div className="card">
                <DataTable
                    ref={dt}
                    value={invoices}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 100, 200]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} dosyadan {first} ila {last} arası gösteriliyor"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="Defter Bulunamadı"
                    className="p-datatable-gridlines"
                    selectionMode={'checkbox'}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="taxId" header="Vergi No" sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="csvFileName" header="Defter Dosya Adı" sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="startDate" header="Başlangıç Tarihi" sortable body={(rowData) => formatDate(rowData.startDate, 'dot')} style={{ minWidth: '6rem' }}></Column>
                    <Column field="endDate" header="Bitiş Tarihi" sortable body={(rowData) => formatDate(rowData.endDate, 'dot')} style={{ minWidth: '6rem' }}></Column>
                    <Column field="status" header="Durum" body={(rowData) => getCSVStatus(rowData.status)} sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="created_at" header="Oluşturulma Tarihi" sortable body={(rowData) => formatDateAndTime(rowData.created_at)} style={{ minWidth: '6rem' }}></Column>
                    <Column header="İşlemler" body={actionBodyTemplate} className="text-center" exportable={false} style={{ minWidth: '5rem' }}></Column>
                </DataTable>
            </div>
            <Dialog visible={deleteVisible} onHide={() => setDeleteVisible(false)} header="Dosya Silme" modal style={{ width: '350px' }} footer={footerTemplate}>
                <div className="flex align-items-center gap-2">
                    <p className="m-0">Bu dosyayı silmek istediğinize emin misiniz ?</p>
                </div>
            </Dialog>
        </div>
    );
}
